/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: SourceSansPro;
  src: url(./assets/fonts/source-Sans-Pro/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: SourceSansPro;
  src: url(./assets/fonts/source-Sans-Pro/SourceSansPro-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: MMTextProTTF;
  src: url(./assets/fonts/MMfonts/MMTextPro-Regular.otf);
}

@font-face {
  font-family: MMTextProBold;
  src: url(./assets/fonts/MMfonts/MMTextPro-Bold.otf);
}

html, body { 
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body {
  margin: 0;
  font-family: SourceSansPro, Roboto, "Helvetica Neue", sans-serif;

  .mm-web-fonts{
    font-family: Myanmar3, Yunghkio, sans-serif;
  }
}

button {
  font-family: SourceSansPro !important;
}

.btn-dark {
  background-color: #000;
}

// * {
//   -webkit-overflow-scrolling: touch;
// }


[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}
/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  //top: 0;
  top: .3em;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #000;
  background: #fff;
  border-radius: 4px;
  // box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: 0.4em;
  left: 0.11em;
  font-size: 1.3em;
  line-height: 0.8;
  //color: #09ad7e;
  color: #333;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 2px dotted blue;
}
/* hover style just for information */
label:hover:before {
  //border: 2px solid #4778d9 !important;
  border: 2px solid #333 !important;
}

.mat-button-toggle-button:focus {
  outline: none;
}

.mat-button-toggle-button {
  text-align: left;
  border-left: none;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border-left: none;
}

mat-expansion-panel-header.active {
  background-color: #333 !important;
  color: white;

  .mat-expansion-indicator::after, .mat-expansion-panel-header-description {
    color: white !important;
  }
  
  mat-panel-title {
    color: white;
  }
}

// .mat-dialog-container {
//   // margin-top: -100%;
//   position: absolute !important;
//   top: 100px !important;
//   height: inherit !important;
  
//   margin-left: auto;
//   margin-right: auto;
//   width: 100% !important;
//   @media screen and (min-width: 600px){
//     width: inherit !important;
//   }
  
// }

.mat-dialog-container {
  padding: 0 !important;
}

#search.mat-dialog-container {
  top: 0 !important;
}

@media screen and (min-width: 750px){
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.btn-dark:disabled {
  color: #bbb;
  background-color: #f8f8f8;
  border-color: #ddd;
  opacity: 0.2;
}

.mat-button-toggle-label-content {
  background: transparent !important;
  z-index: 2 !important;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: none !important;
  display: none !important;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-color: #ccc !important;
}

.mat-form-field-flex {
  border: 1px solid #ccc !important;
  align-items: center !important;
}